import cx from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import { useLayoutEffect, useRef } from 'react';
import { FormattedFAQCategoryType } from '../../types';
import styles from './CatList.module.scss';

const FAQCategory = ({
  category,
  majorCatSlug,
  activeCatSlug,
  activeCatRef,
}: {
  category: FormattedFAQCategoryType;
  majorCatSlug: string;
  activeCatSlug: string;
  activeCatRef: any;
}) => {
  const normalizeSlug = category.slug.replaceAll('_', '-');

  return activeCatSlug === category.slug ? (
    <span className={cx(styles.cat, styles.activeCat)} ref={activeCatRef}>
      {category.title}
    </span>
  ) : (
    <Link
      to={`/support/${majorCatSlug}/${normalizeSlug}#faq`}
      className={cx(styles.cat)}
    >
      {category.title}
    </Link>
  );
};

const OFFSET = 48;

const FAQCategories = ({
  categories,
  majorCatSlug,
  activeCatSlug,
}: {
  categories: Array<FormattedFAQCategoryType>;
  majorCatSlug: string;
  activeCatSlug: string;
}) => {
  const catsRef = useRef<HTMLDivElement>(null);
  const activeCatRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (catsRef.current && activeCatRef.current) {
      catsRef.current.scrollLeft = activeCatRef.current.offsetLeft - OFFSET;
    }
  }, []);

  return (
    <div
      id="faq"
      className={cx(styles.cats, styles[majorCatSlug])}
      ref={catsRef}
    >
      {categories?.map((category) => {
        return (
          <FAQCategory
            category={category}
            majorCatSlug={majorCatSlug}
            activeCatSlug={activeCatSlug}
            key={category.slug}
            activeCatRef={activeCatRef}
          />
        );
      })}
    </div>
  );
};

export default FAQCategories;
