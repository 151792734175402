import { TFunction } from 'gatsby-plugin-react-i18next';
import { FormattedFAQCategoryType, FormattedFAQType } from '../../types';
import { isIntersected } from '../../utils';
import List from './List';
import styles from './SearchResult.module.scss';

const FAQCategory = ({
  majorCatSlug,
  category,
  faqs,
}: {
  majorCatSlug: string;
  category: FormattedFAQCategoryType;
  faqs: Array<FormattedFAQType>;
}) => {
  const filteredFAQs = faqs.filter((faq) =>
    isIntersected(
      [category.slug],
      faq.cats.map((cat) => cat.slug)
    )
  );

  return (
    <div className={styles.cat}>
      <h4 className={styles.catTitle}>{category.title}</h4>
      <List faqs={filteredFAQs} majorCatSlug={majorCatSlug} />
    </div>
  );
};

const SearchResult = ({
  categories,
  majorCatSlug,
  t,
}: {
  categories: Array<FormattedFAQCategoryType>;
  majorCatSlug: string;
  t: TFunction;
}) => {
  return (
    <>
      {categories.length > 0 ? (
        categories?.map((category) => {
          return (
            <FAQCategory
              category={category}
              majorCatSlug={majorCatSlug}
              faqs={category.faqs || []}
              key={category.slug}
            />
          );
        })
      ) : (
        <p className={styles.emptyResult}>{t('nothing-found')}</p>
      )}
    </>
  );
};

export default SearchResult;
