import * as Sentry from '@sentry/gatsby';
import axios from 'axios';
import cx from 'classnames';
import Fuse from 'fuse.js';
import { graphql } from 'gatsby';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Markdown from 'markdown-to-jsx';
import queryString from 'query-string';
import {
  FormEvent,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import srcAlert from '../assets/support/alert.svg';
import FAQCats from '../components/FAQ/CatList';
import FAQs from '../components/FAQ/List';
import FAQSearchResult from '../components/FAQ/SearchResult';
import Button from '../components/Form/Button';
import Layout from '../components/Layout/Layout';
import {
  FAQCategoryType,
  FAQType,
  FormattedFAQType,
  StripType,
} from '../types';
import {
  getFormattedCats,
  getFormattedFAQs,
  isIntersected,
  nestingSearchFAQ,
  normalizeCats,
} from '../utils';
import styles from './Support.module.scss';

type MajorCatSlug = 'consumer' | 'merchant' | 'other';
type InquiryCategory = {
  id: string;
  i18nKey: string;
  extraFields: string[];
  hideFields?: string[];
};
type InquiryState =
  | 'init'
  | 'inquiring'
  | 'previewing'
  | 'submitting'
  | 'submitted';

type InquiryFormInput = {
  inquiryCategory: string;
  name: string;
  nameYomikata: string;
  email: string;
  phone: string;
  orderId: string;
  website: string;
  inquiry: string;
};

const HEADER_HEIGHT = 64;

const MAJOR_CATS: MajorCatSlug[] = ['consumer', 'merchant', 'other'];

const INQUIRY_CATEGORIES: InquiryCategory[] = [
  { id: 'usage', i18nKey: 'inquiry-cat-usage', extraFields: [] },
  { id: 'account', i18nKey: 'inquiry-cat-account', extraFields: ['phone'] },
  { id: 'login', i18nKey: 'inquiry-cat-login', extraFields: ['phone'] },
  { id: 'ekyc', i18nKey: 'inquiry-cat-ekyc', extraFields: ['phone'] },
  {
    id: 'order-payment',
    i18nKey: 'inquiry-cat-order-payment',
    extraFields: ['orderId'],
  },
  {
    id: 'order-history',
    i18nKey: 'inquiry-cat-order-history',
    extraFields: ['orderId'],
  },
  {
    id: 'close-account',
    i18nKey: 'inquiry-cat-close-account',
    extraFields: [],
  },
  { id: 'payout', i18nKey: 'inquiry-cat-payout', extraFields: ['website'] },
  {
    id: 'dashboard',
    i18nKey: 'inquiry-cat-dashboard',
    extraFields: ['website'],
  },
  {
    id: 'order',
    i18nKey: 'inquiry-cat-order',
    extraFields: ['website', 'orderId'],
  },
  {
    id: 'cancel-and-refund',
    i18nKey: 'inquiry-cat-cancel-and-refund',
    extraFields: ['website', 'orderId'],
  },
  { id: 'system-error', i18nKey: 'inquiry-cat-system-error', extraFields: [] },
  { id: 'consumer-other', i18nKey: 'inquiry-cat-other', extraFields: [] },
  {
    id: 'merchant-other',
    i18nKey: 'inquiry-cat-other',
    extraFields: ['website'],
  },
  {
    id: 'other',
    i18nKey: 'inquiry-cat-other',
    extraFields: ['phone'],
    hideFields: ['category'],
  },
];

const CONSUMER_INQUIRY_CATEGORIES = [
  'usage',
  'account',
  'login',
  'ekyc',
  'order-payment',
  'order-history',
  'close-account',
  'consumer-other',
];

const MERCHANT_INQUIRY_CATEGORIES = [
  'payout',
  'dashboard',
  'order',
  'cancel-and-refund',
  'system-error',
  'merchant-other',
];

const DEFAULT_INQUIRY_CATEGORY_SET_MAPPING = {
  ['consumer' as MajorCatSlug]: CONSUMER_INQUIRY_CATEGORIES,
  ['merchant' as MajorCatSlug]: MERCHANT_INQUIRY_CATEGORIES,
  ['other' as MajorCatSlug]: ['other'],
};

export default function Support({
  location,
  pageContext: { majorCatSlug, majorCatSlugAlias },
  data: {
    contentful: {
      faqCollection: { items: rawFAQs },
      faqCategoryCollection: { items: rawFAQCategories },
      faqCategory: category,
      stripCollection: { items: strips },
    },
  },
}: {
  location: Location;
  pageContext: {
    majorCatSlug: string;
    majorCatSlugAlias: string;
    root: boolean;
  };
  data: {
    contentful: {
      faqCategory: FAQCategoryType;
      faqCategoryCollection: {
        items: Array<FAQCategoryType>;
      };
      faqCollection: {
        items: Array<FAQType>;
      };
      stripCollection: {
        items: Array<StripType>;
      };
    };
  };
}) {
  const { t, originalPath, navigate, i18n } = useI18next();
  const [error, setError] = useState('');

  const IQUIRY_CATEGORY_OPTIONS =
    DEFAULT_INQUIRY_CATEGORY_SET_MAPPING[majorCatSlugAlias as MajorCatSlug] ||
    DEFAULT_INQUIRY_CATEGORY_SET_MAPPING.consumer;

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      inquiryCategory: IQUIRY_CATEGORY_OPTIONS[0],
      name: '',
      nameYomikata: '',
      email: '',
      phone: '',
      orderId: '',
      website: '',
      inquiry: '',
    },
  });

  const [inquiryState, setInquiryState] = useState<InquiryState>('init');
  const inquiryCategory = watch('inquiryCategory');
  const inquiryCategoryData = INQUIRY_CATEGORIES.find(
    (item) => item.id === inquiryCategory
  );
  const extraFields = inquiryCategoryData?.extraFields || [];
  const hideFields = inquiryCategoryData?.hideFields || [];
  const inquiry = watch('inquiry');

  const faqCategories = getFormattedCats(rawFAQCategories);
  const majorCat = faqCategories.find((cat) => cat.slug === majorCatSlug); // merchant or consumer
  const activeSubCats = normalizeCats(majorCat?.subCats, faqCategories); // cats belongs to the active major Cat
  const subCats = normalizeCats(majorCat?.subCats, faqCategories);

  const faqs = getFormattedFAQs(rawFAQs);
  const filteredFAQs = faqs.filter((faq) =>
    isIntersected(
      [majorCatSlug, category.slug],
      faq.cats.map((cat) => cat.slug)
    )
  );

  const { q } = queryString.parse(location.search);
  const query = ((Array.isArray(q) ? q[0] : q) || '').trim();
  const [keyword, setKeyword] = useState(query);

  const onSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    navigate(
      `/support/${majorCatSlug}/${category.slug}${
        keyword
          ? '?' +
            queryString.stringify({
              q: keyword,
            })
          : ''
      }`
    );
  };

  const onSubmitInquiry = (data: InquiryFormInput) => {
    setInquiryState('submitting');
    setError('');

    const endpoint =
      process.env.GATSBY_SUPPORT_INQUIRY_URL || '/api/support/inquiry/form';
    const payload = { ...data, submitterCategory: majorCat?.slug };

    axios
      .post(endpoint, payload)
      .then(() => {
        navigate('/contact-us-submitted/', { replace: true });
      })
      .catch((error) => {
        Sentry.captureException(error);
        setInquiryState('previewing');
        setError('unexpected_error');
      });
  };

  const fuse = useMemo(() => {
    return new Fuse<FormattedFAQType>(faqs, {
      keys: ['title', 'answerText', 'keywords'],
    });
  }, [faqs]);

  const searchResult = useMemo(
    () =>
      nestingSearchFAQ({
        majorCat,
        query,
        fuse,
        faqs,
        categories: activeSubCats,
      }),
    [majorCat, query, faqs, activeSubCats]
  );

  useLayoutEffect(() => {
    if (['inquiring', 'previewing'].includes(inquiryState)) {
      setTimeout(() => {
        window.scrollTo(
          0,
          (document.getElementById('contact_us')?.offsetTop || 0) -
            HEADER_HEIGHT
        );
      }, 5);
    }
  }, [inquiryState]);

  useEffect(() => {
    if (location.hash === '#contact_us') {
      setInquiryState('inquiring');
    }
  }, [location]);

  return (
    <Layout
      title={`${category.title} | ${t(`${majorCatSlugAlias}-faq-title-sec-2`)}`}
      description={t(`${majorCatSlugAlias}-faq-description`)}
      className={cx(styles.mainWrapper, styles[majorCatSlugAlias])}
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      strips={strips}
    >
      {['init', 'inquiring'].includes(inquiryState) && (
        <div className={styles.main}>
          <div className={styles.header}>
            <h1>{t('support-center')}</h1>
            <nav>
              {MAJOR_CATS.map((majorCat) => (
                <Link
                  to={`/support/${majorCat}/`}
                  key={majorCat}
                  className={
                    majorCat === majorCatSlugAlias ? styles.active : ''
                  }
                >
                  {t(`support-${majorCat}`)}
                </Link>
              ))}
            </nav>
            <p>
              <Trans
                i18nKey={`${majorCatSlugAlias}-faq-desc`}
                components={{ br: <br /> }}
              />
            </p>

            <form className={styles.searchForm} onSubmit={onSearch}>
              <input
                id="search"
                className={styles.search}
                placeholder={t('search-placeholder')}
                value={keyword}
                onChange={(event) => {
                  setKeyword(event.target.value);
                }}
              />
            </form>
          </div>
          {query ? (
            <>
              <div className={styles.searchResult}>
                <FAQSearchResult
                  categories={searchResult}
                  majorCatSlug={majorCatSlug}
                  t={t}
                />
                <Link
                  to={`/support/${majorCatSlug}/${category.slug}`}
                  className={styles.searchResultBack}
                >
                  {t('back-to-faq')}
                </Link>
              </div>
            </>
          ) : (
            <>
              <FAQCats
                categories={subCats}
                majorCatSlug={majorCatSlugAlias}
                activeCatSlug={category.slug}
              />
              <FAQs
                faqs={filteredFAQs}
                majorCatSlug={majorCatSlugAlias}
                className={styles.faqList}
              />
            </>
          )}
          <div id="contact_us" className={styles.contactUs}>
            {inquiryState === 'init' && (
              <div className={styles.main}>
                <h2 className={styles.noDesc}>
                  {t('support-not-found-title')}
                </h2>

                <button
                  type="button"
                  className={styles.mainAction}
                  onClick={() => setInquiryState('inquiring')}
                >
                  {t('support-not-found-action')}
                </button>
              </div>
            )}
            {inquiryState === 'inquiring' && (
              <div className={styles.content}>
                <h2>
                  <Trans i18nKey="contact-us-title" />
                </h2>
                <p className={styles.desc}>
                  <Trans i18nKey="contact-us-description" />
                </p>

                <form
                  className={styles.form}
                  onSubmit={handleSubmit(() => setInquiryState('previewing'))}
                >
                  <div className={styles.fields}>
                    <div className={styles.row}>
                      <div className={styles.field}>
                        <label htmlFor="name">
                          {t('contact-form-name')}{' '}
                          <span className={styles.required}>*</span>
                        </label>
                        <input
                          id="name"
                          placeholder="須磨 愛里"
                          required
                          {...register('name', { required: true })}
                        />
                      </div>
                      {i18n.language === 'ja' && (
                        <div className={styles.field}>
                          <label htmlFor="name">
                            {t('contact-form-name-yomikata')}{' '}
                            <span className={styles.required}>*</span>
                          </label>
                          <input
                            id="name_yomikata"
                            placeholder="すま あいり"
                            required
                            {...register('nameYomikata', { required: true })}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="email">
                      {t('contact-form-email')}{' '}
                      <span className={styles.required}>*</span>
                    </label>
                    <div className={styles.row}>
                      <input
                        type="email"
                        id="email"
                        placeholder="mail@mail.com"
                        required
                        {...register('email', { required: true })}
                      />
                    </div>
                  </div>
                  {!hideFields.includes('category') && (
                    <div className={styles.field}>
                      <label htmlFor="inquiry-category">
                        {t('contact-form-inquiry-category')}{' '}
                        <span className={styles.required}>*</span>
                      </label>
                      <select
                        id="inquiry-category"
                        {...register('inquiryCategory', { required: true })}
                      >
                        {IQUIRY_CATEGORY_OPTIONS.map((key) => {
                          const option = INQUIRY_CATEGORIES.find(
                            (item) => item.id === key
                          );

                          if (!option) {
                            return null;
                          }

                          return (
                            <option
                              value={option.id}
                              selected={inquiryCategory === option.id}
                              key={key}
                            >
                              {t(option.i18nKey)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {extraFields.includes('phone') && (
                    <div className={styles.field}>
                      <label htmlFor="phone">
                        {t('contact-form-phone')}{' '}
                        <span className={styles.required}>*</span>
                      </label>
                      <input
                        id="phone"
                        placeholder="08012345678"
                        required
                        {...register('phone', { required: true })}
                      />
                    </div>
                  )}
                  {extraFields.includes('website') && (
                    <div className={styles.field}>
                      <label htmlFor="website">
                        {t('contact-form-website-name')}{' '}
                        <span className={styles.required}>*</span>
                      </label>
                      <input
                        id="website"
                        placeholder="Your Online Shop"
                        required
                        {...register('website', { required: true })}
                      />
                    </div>
                  )}
                  {extraFields.includes('orderId') && (
                    <div className={styles.field}>
                      <label htmlFor="order_id">
                        <Trans
                          i18nKey="contact-form-order-id"
                          components={{ span: <span /> }}
                        />{' '}
                        <span className={styles.required}>*</span>
                      </label>
                      <input
                        id="order_id"
                        placeholder="order_live_XXXXXXXXXXXXXXXXXXXXXX"
                        required
                        {...register('orderId', { required: true })}
                      />
                    </div>
                  )}
                  <div className={styles.field}>
                    <label htmlFor="inquiry">
                      {t(
                        inquiryCategory === 'system-error'
                          ? 'contact-form-system-error-inquiry'
                          : 'contact-form-inquiry'
                      )}{' '}
                      <span className={styles.required}>*</span>
                    </label>
                    <textarea
                      id="inquiry"
                      placeholder={t('contact-form-inquiry-placeholder')}
                      required
                      {...register('inquiry', { required: true })}
                    ></textarea>
                  </div>
                  {inquiryCategory === 'close-account' && (
                    <>
                      <div className={styles.information}>
                        <Markdown>{t('how-to-close-account')}</Markdown>
                      </div>
                      <div className={cx(styles.information, styles.warning)}>
                        <img
                          className={styles.alert}
                          alt=""
                          src={srcAlert}
                          width="64"
                          height="56"
                        />
                        <Markdown options={{ forceBlock: true }}>
                          {t('notice-before-close-account')}
                        </Markdown>
                      </div>
                    </>
                  )}
                  <div className={styles.actions}>
                    <Button
                      type="submit"
                      disabled={!isValid}
                      className={styles.action}
                      label={t('to-confirm')}
                    />
                  </div>
                </form>
                <div className={styles.phoneContactUsBlock}>
                  <h2>{t('contact-us-phone-title')}</h2>
                  <div className={styles.phone}>050-3537-4983</div>
                  <div className={styles.serviceTime}>
                    <Trans
                      i18nKey="contact-us-phone-desc"
                      components={{ b: <b />, span: <span /> }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {['previewing', 'submitting'].includes(inquiryState) && (
        <div className={styles.main}>
          <div id="contact_us" className={styles.contactUs}>
            <div className={styles.content}>
              <h2>
                <Trans i18nKey="contact-us-preview-title" />
              </h2>
              <p className={styles.desc}>
                <Trans i18nKey="contact-us-preview-description" />
              </p>

              <form
                className={styles.preview}
                onSubmit={handleSubmit(onSubmitInquiry)}
              >
                <div className={styles.fields}>
                  <div className={styles.row}>
                    <div className={styles.field}>
                      <label htmlFor="name">{t('contact-form-name')}</label>
                      <input
                        id="name"
                        readOnly
                        {...register('name', { required: true })}
                      />
                    </div>
                    {i18n.language === 'ja' && (
                      <div className={styles.field}>
                        <label htmlFor="name">
                          {t('contact-form-name-yomikata')}
                        </label>
                        <input
                          id="name_yomikata"
                          readOnly
                          {...register('nameYomikata', { required: true })}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.field}>
                  <label htmlFor="email">{t('contact-form-email')}</label>
                  <div className={styles.row}>
                    <input
                      type="email"
                      id="email"
                      readOnly
                      {...register('email', { required: true })}
                    />
                  </div>
                </div>
                <div className={styles.field}>
                  <label htmlFor="inquiry-category">
                    {t('contact-form-inquiry-category')}
                  </label>
                  <input
                    id="inquiry-category"
                    readOnly
                    value={t(inquiryCategoryData?.i18nKey || '')}
                  />

                  <input
                    id="inquiry-category"
                    type="hidden"
                    readOnly
                    {...register('inquiryCategory', { required: true })}
                  />
                </div>
                {extraFields.includes('phone') && (
                  <div className={styles.field}>
                    <label htmlFor="phone">{t('contact-form-phone')}</label>
                    <input
                      id="phone"
                      readOnly
                      {...register('phone', { required: true })}
                    />
                  </div>
                )}
                {extraFields.includes('website') && (
                  <div className={styles.field}>
                    <label htmlFor="website">
                      {t('contact-form-website-name')}
                    </label>
                    <input
                      id="website"
                      readOnly
                      {...register('website', { required: true })}
                    />
                  </div>
                )}
                {extraFields.includes('orderId') && (
                  <div className={styles.field}>
                    <label htmlFor="order_id">
                      <Trans
                        i18nKey="contact-form-order-id"
                        components={{ span: <span /> }}
                      />
                    </label>
                    <input
                      id="order_id"
                      readOnly
                      {...register('orderId', { required: true })}
                    />
                  </div>
                )}

                <div className={styles.field}>
                  <label htmlFor="inquiry">
                    {t(
                      inquiryCategory === 'system-error'
                        ? 'contact-form-system-error-inquiry'
                        : 'contact-form-inquiry'
                    )}
                  </label>
                  <div className={styles.textarea}>
                    {inquiry
                      .split('\n')
                      .reduce<Array<ReactNode | string>>(
                        (accum, line) => [...accum, line, <br />],
                        []
                      )}
                  </div>
                  <textarea
                    id="inquiry"
                    readOnly
                    {...register('inquiry', { required: true })}
                  ></textarea>
                </div>
                <div className={styles.actions}>
                  <Button
                    type="button"
                    variant="secondary"
                    label={t('edit')}
                    className={cx(styles.action, styles.secondary)}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      setInquiryState('inquiring');
                    }}
                  />
                  <Button
                    type="submit"
                    disabled={!isValid}
                    loading={isSubmitting}
                    className={styles.action}
                    label={t('send')}
                  />
                </div>
                {error && (
                  <div className={styles.errorWrapper}>
                    <p id="error_message">{t('unexpected-error')}</p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query SupportPageQuery($id: String!, $language: String!, $dev: Boolean!) {
    contentful {
      faqCategory(id: $id, locale: $language, preview: $dev) {
        slug
        title
      }
      faqCollection(locale: $language, order: order_ASC, preview: $dev) {
        items {
          sys {
            id
          }
          title
          titleEn: title(locale: "en")
          order
          answer {
            json
          }
          categoryCollection {
            items {
              sys {
                id
              }
              slug
              title
            }
          }
        }
      }
      faqCategoryCollection(locale: $language, preview: $dev) {
        items {
          title
          slug
          subCategoriesCollection {
            items {
              slug
              sys {
                id
              }
            }
          }
          sys {
            id
          }
        }
      }
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
