import cx from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import slugify from 'slugify';
import iconArrowOther from '../../assets/icons/icon-chevron-right-blackberry.svg';
import iconArrowConsumer from '../../assets/icons/icon-chevron-right-very-light-green.svg';
import iconArrowMerchant from '../../assets/icons/icon-chevron-right.svg';
import { CompactFAQType } from '../../types';
import styles from './List.module.scss';

const arrow = (cat: string) => {
  if (cat === 'consumer') {
    return iconArrowConsumer;
  } else if (cat === 'other') {
    return iconArrowOther;
  }

  return iconArrowMerchant;
};

const FAQ = ({
  faq,
  majorCatSlug,
}: {
  faq: CompactFAQType;
  majorCatSlug: string;
}) => {
  return (
    <Link
      to={`/faq/${majorCatSlug}/entry/${slugify(faq.titleEn, {
        lower: true,
        strict: true,
      })}`}
      className={styles.entry}
    >
      <span>{faq.title}</span>
      <img
        src={arrow(majorCatSlug)}
        width={24}
        height={24}
        alt=""
        loading="lazy"
      />
    </Link>
  );
};

const FAQs = ({
  faqs,
  majorCatSlug,
  className,
}: {
  faqs: Array<CompactFAQType>;
  majorCatSlug: string;
  className?: string;
}) => {
  return (
    <div className={cx(styles.entries, className)}>
      {faqs.map((faq) => (
        <FAQ key={faq.id} faq={faq} majorCatSlug={majorCatSlug} />
      ))}
    </div>
  );
};

export default FAQs;
